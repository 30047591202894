// @ts-nocheck
import React, {useEffect, useRef} from "react";
import * as d3 from "d3";
import nigeria from "../data/nigeria-states.json";
import * as topojson from "topojson-client"
import './NigeriaMap.css'

export const NigeriaMap = () => {
    const mapRef = useRef(null);
    const width = 1170; //975
    const height = 732; // 610

    useEffect(() => {

        const svg = d3.select(mapRef.current);
        const geoJSON = topojson.feature(nigeria, nigeria.objects.states);

        const projection = d3.geoMercator()
            .fitSize([width, height], geoJSON);

        const pathGenerator = d3.geoPath().projection(projection)
        svg.selectAll('path')
            .data(geoJSON.features)
            .enter().append('path')
            .attr('d', pathGenerator)
            .attr("cursor", "pointer")
            .style('fill', 'MediumSeaGreen')
            .style('stroke', 'white')
            .append('title').text(d => d.properties['NAME_1'])

    }, []);

    return (
        <div className={'map-container'}>
            <svg ref={mapRef} width={width} height={height} viewBox={`0 0 ${width} ${height}`}/>
        </div>
    );
}