import './Features.css'
import landscape from '../images/precious-iroagalachi-Qz5SMd6M700-unsplash.jpg'
import culture from '../images/pexels-tope-a-asokere-3316259.jpg'
import attractions from '../images/nupo-deyon-daniel-67ruAEYmp4c-unsplash.jpg'
import people from "../images/pexels-ottun-abdulmalik-7122397.jpg"

export const Features = () => {
    return (
        <section className="features">
            <h2 className="features-title">Experience the Beauty of Nigeria</h2>
            <div className="features-cards">
                <div className="feature-card">
                    <img className="feature-image"  src={landscape} alt="Nigeria Landscape" />
                    <h3 className="feature-title">Diverse Landscapes</h3>
                    <p className="feature-description">Discover Nigeria's diverse landscapes, from the rainforests in the south to the savannahs in the north.</p>
                </div>
                <div className="feature-card">
                    <img className="feature-image"  src={culture} alt="Nigeria Culture" />
                    <h3 className="feature-title">Rich Culture</h3>
                    <p className="feature-description">Experience Nigeria's rich and diverse culture, from music and dance to art and fashion.</p>
                </div>
                <div className="feature-card">
                    <img className="feature-image"  src={attractions} alt="Nigeria Attraction" />
                    <h3 className="feature-title">Famous Attractions</h3>
                    <p className="feature-description">Visit Nigeria's famous attractions, such as the Olumo Rock, Yankari National Park, and Lagos beaches.</p>
                </div>
                <div className="feature-card">
                    <img className="feature-image" src={people} alt="Nigeria People" />
                    <h3 className="feature-title">Friendly People</h3>
                    <p className="feature-description">Experience the warmth and hospitality of Nigeria's friendly and welcoming people.</p>
                </div>
            </div>
        </section>
    );
}