import React, {useEffect, useState} from 'react';
import './App.css';
import {NigeriaMap} from "./landing/NigeriaMap";
import {Header} from './header/Header';
import {Footer} from "./footer/Footer";
import {Features} from "./landing/Features";

let url = "https://en.wikipedia.org/w/api.php";

const params: {[key: string]: string} = {
    action: "query",
    titles: "Nigeria",
    format: "json",
    redirects: "1",
    prop: "extracts",
    explaintext:"",
    exintro: ""
};

url = url + "?origin=*";
Object.keys(params).forEach(function(key){url += `&${key}=${params[key]}`;});

export const App = () => {
    const [content, setContent] = useState<string>();

    useEffect(() => {
        const history = localStorage.getItem("history");
        if (history == null) {
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    const dataToStore = data.query.pages["21383"].extract;
                    localStorage.setItem("history", dataToStore);
                    setContent(dataToStore);
                })
                .catch(console.log);
        } else {
            setContent(history);
        }
    }, []);

    return (
        <div className={'App-landing-page'}>
            <Header />
            <main className={'main-content'}>
                <NigeriaMap />
                <Features />
            </main>
            <Footer />
        </div>
    );
};
