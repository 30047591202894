import React from "react";
import './Header.css'

export const Header = () => {
    return (
        <header className={'App-header'}>
            <h1 className={'App-logo'}>9jaDun</h1>
            <nav>
                <ul>
                    <li><a href="src/header/Header#">Explore</a></li>
                    <li><a href="src/header/Header#">Culture</a></li>
                    <li><a href="src/header/Header#">Attractions</a></li>
                    <li><a href="src/header/Header#">Blog</a></li>
                    <li><a href="src/header/Header#">Contact Us</a></li>
                </ul>
            </nav>
        </header>
    );
}